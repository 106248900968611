
  export default {
    name: `SearchBar`,
    data() {
      return {
        searchModel: ``,
        loading: false
      }
    },
    methods: {
      async search() {
        this.loading = true
        const search = this.searchModel.trim().length ? { search: this.searchModel.trim() } : {}
        await this.$store.dispatch(`marketplace/getMyChecks`, search)
        this.loading = false
      }
    }
  }

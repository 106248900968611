
  export default {
    name: `MyCheckDocumentsDialog`,
    data() {
      return {
        toggleDialog: false
      }
    },
    props: {
      documents: {
        type: Array,
        default: () => []
      }
    }
  }


  import { mapGetters } from 'vuex'
  import SearchBar from '~/components/marketplace/my_checks/SearchBar.vue'
  import MyCheckDocumentsDialog from '~/components/marketplace/my_checks/MyCheckDocumentsDialog.vue'

  export default {
    name: `MyChecksPage`,
    components: {
      SearchBar,
      MyCheckDocumentsDialog
    },
    data() {
      return {
        searchValue: undefined,
        paginator: undefined,
        stateAttrs: {
          ordered_placed: {
            icon: `mdi-circle-outline`,
            color: `primary`
          },
          in_progress: {
            icon: `mdi-circle-slice-3`,
            color: `primary`
          },
          completed: {
            icon: `mdi-checkbox-marked-circle`,
            color: `primary`
          },
          cancelled: {
            icon: `mdi-close-circle`,
            color: `error`
          }
        },
        title: {
          Mr: this.$t(`components.genders.m`),
          Ms: this.$t(`components.genders.f`)
        }
      }
    },
    fetch() {
      this.getMyChecks().then(this.initPagination)
    },
    async destroyed() {
      await this.$store.commit(`marketplace/defaultMyChecks`)
    },
    computed: {
      ...mapGetters({
        myChecks: `marketplace/myChecks`
      })
    },
    watch: {
      myChecks() {
        this.initPagination()
      }
    },
    methods: {
      getMyChecks() {
        return this.$store.dispatch(`marketplace/getMyChecks`, this.getFilters())
      },
      getFilters() {
        const page = !!this.paginator?.page && this.paginator.page !== 1 ? { page: this.paginator.page } : {}
        const search = !!this.searchValue && this.searchValue.trim().length ? { search: this.searchValue } : {}
        return { ...search, ...page }
      },
      getFormattedDate(date) {
        return !!date ? this.$moment(date, `DD.MM.YYYY`).format(`DD.MM.YYYY`) : ``
      },
      initPagination() {
        this.paginator = { ...this.myChecks.pagination }
      }
    }
  }
